<template>
  <!-- 抬头管理 -->
  <div class="lookedUpManagement">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :search-form-item-arr="searchFormItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="editAndAddRow">
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 弹出的新增跟修改框 -->
    <Dialog ref="dialog" column="2" :edit-form-data="editFormData" :add-and-edit-form-item="addAndEditFormItem" @getFormData="getFormData" />
    <!-- 详情弹出层 -->
    <el-dialog
      title="详情"
      :visible.sync="editFormVisible"
      width="62%"
      class="dialog"
      :close-on-click-modal="false"
      @click="editFormVisible = false"
    >
      <el-descriptions title="发票详情" :column="2" :size="''" border>
        <el-descriptions-item>
          <template slot="label">
            企业名称
          </template>
          {{ memberInformation.cmpName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            发票抬头
          </template>
          {{ memberInformation.invoiceName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            纳税人识别号
          </template>
          {{ memberInformation.cmpUnicode }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            社会信用代码
          </template>
          {{ memberInformation.invoiceUnicode }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            开户行名称
          </template>
          {{ memberInformation.invoiceBankName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            开户行账号
          </template>
          {{ memberInformation.invoiceBankCode }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            公司地址
          </template>
          {{ memberInformation.invoiceAddr }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            公司联系电话
          </template>
          {{ memberInformation.invoiceTel }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="marginTop" title="接收信息" :column="2" :size="''" border>
        <el-descriptions-item>
          <template slot="label">
            电子邮箱
          </template>
          {{ memberInformation.recMail }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            收货地址
          </template>
          {{ memberInformation.recAddr }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            联系人姓名
          </template>
          {{ memberInformation.recName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            联系人电话
          </template>
          {{ memberInformation.recTel }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            是否默认
          </template>
          {{ memberInformation.isDefault === '00' ? '是' : '否' }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import Dialog from '@/components/Dialog'
import { intoParty } from '@/config/index'
import { rules, formPattern } from '@/unit/matchFormRegex'
import { cmpInvoicePage, cmpInvoiceUpdateAndAdd, cmpInvoiceEnableOrDisablt, cmpInvoiceUpdateDefaultAddress } from '@/api/invoiceManagement'
export default {
  components: { FormSearch, Table, Pagination, Dialog },
  data() {
    return {
      editFormVisible: false,
      memberInformation: {},
      editFormData: {},
      itemData: [
        // { label: '会员名称', prop: 'cmpName', width: 180 },
        { label: '发票抬头', prop: 'invoiceName', width: 180 },
        { label: '纳税人识别号', prop: 'cmpUnicode', width: 180 },
        { label: '开户行名称', prop: 'invoiceBankName', width: 180 },
        { label: '公司联系电话', prop: 'invoiceTel', width: 180 },
        { label: '联系人姓名', prop: 'recName', width: 180 },
        { label: '电子邮箱', prop: 'recMail', width: 180 },
        { label: '状态', prop: 'delFlag', child: [{ id: '0', name: '启用' }, { id: '1', name: '禁用' }] },
        { label: '录入方', prop: 'dataSource', width: 180, child: intoParty }
      ],
      addAndEditFormItem: [
        { label: '企业抬头', type: 'input', value: 'invoiceName', rules: [...rules.name] },
        { label: '社会信用代码', type: 'input', value: 'invoiceUnicode', rules: [...rules.unicode] },
        { label: '公司联系电话', type: 'input', value: 'invoiceTel', rules: [
          { required: true, message: '请输入公司联系电话', trigger: 'blur' },
          { min: 1, max: 15, message: '长度在 1 到 15 个字符' },
          { ...formPattern.contactWay }
        ] },
        { label: '公司地址', type: 'input', value: 'invoiceAddr', rules: [...rules.name] },
        { label: '开户行名称', type: 'input', value: 'invoiceBankName', rules: [...rules.name] },
        { label: '纳税人识别号', type: 'input', value: 'cmpUnicode', rules: [...rules.name] },
        {
          label: '开户行账号', type: 'input', value: 'invoiceBankCode', inputType: 'number', rules: [
            { required: true, message: '请输入开户行账号', trigger: 'blur' },
            { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
          ]
        },
        {
          label: '电子邮箱', type: 'input', value: 'recMail', rules: [
            { required: true, message: '请输入电子邮箱', trigger: 'blur' },
            { pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, required: true, message: '请输入正确的电子邮箱', trigger: 'blur' }
          ]
        },
        { label: '收件地址', type: 'input', value: 'recAddr', rules: [...rules.name] },
        { label: '联系人姓名', type: 'input', value: 'recName', rules: [...rules.name] },
        {
          label: '联系人电话', type: 'input', value: 'recTel', rules: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { ...formPattern.tel }
          ]
        },
        {
          label: '是否默认发票',
          type: 'radio',
          child: [{ value: '00', label: '是' }, { value: '01', label: '否' }],
          value: 'isDefault'
        }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow },
        { num: '01', val: 'isDefault', bType: 'primary', label: '默认发票', handleEvent: this.defaultAddress },
        // { num: 1, val: 'delFlag', bType: 'primary', label: '启用', handleEvent: this.enableDisableRow },
        { num: 0, val: 'delFlag', bType: 'danger', label: '删除', handleEvent: this.enableDisableRow },
        { bType: 'primary', label: '详情', handleEvent: this.detailsRow }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      searchFormItemArr: [
        // { type: 'input', label: '会员名称', value: 'cmpName' },
        { type: 'input', label: '发票抬头', value: 'invoiceName' },
        { type: 'select', label: '录入方', value: 'dataSource', pLabel: 'name', pValue: 'id', child: intoParty }
      ]
    }
  },
  created() {
    this.formInline.cmpCode = this.$getUserInfoParameter('cmpId')
  },
  methods: {
    // 获取列表数据
    getdata(type) {
      if (!type) this.formInline.pageNum = 1
      cmpInvoicePage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 新增或修改数据
    editAndAddRow(row) {
      this.$refs.dialog.editFormVisible = true
      if (row.constructor === Object) {
        this.editFormData = { ...row }
        return
      }
      this.editFormData = {
        invoiceUnicode: '',
        invoiceName: this.$getUserInfoParameter('orgName')
      }
    },
    // details详情
    detailsRow(row) {
      this.editFormVisible = true
      this.memberInformation = { ...row }
    },
    // 设置默认发票
    defaultAddress(row) {
      this.$confirm(`您将此条设为${row.cmpName}的默认发票, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        cmpInvoiceUpdateDefaultAddress({ id: row.id }, () => {
          this.$message.success('成功！')
          this.getdata(true)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 启用与禁用
    enableDisableRow(row) {
      this.$confirm('此操作将改变此条信息状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cmpInvoiceEnableOrDisablt({ id: row.id, delFlag: row.delFlag === 0 ? 1 : 0 }, res => {
          this.$message.success('成功！')
          this.getdata(true)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 获取到输入框的内容
    getFormData(value) {
      // 录入方是企业
      if (!value.id) {
        value.cmpCode = this.$getUserInfoParameter('cmpId')
        value.cmpName = this.$getUserInfoParameter('userName')
        value.dataSource = '00'
      }
      cmpInvoiceUpdateAndAdd(value, () => {
        this.getdata(value.id)
        this.$message.success('成功！')
        this.$refs.dialog.editFormVisible = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.marginTop{
  padding-top: 18px;
}
</style>
