import { postRequest } from './index'
// 企业开票信息分页
export const cmpInvoicePage = (data, successCallback, failureCallback) => {
  postRequest('/cmp/invoice/page', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业开票信息新增与修改
export const cmpInvoiceUpdateAndAdd = (data, successCallback, failureCallback) => {
  postRequest(data.id ? '/cmp/invoice/update' : '/cmp/invoice/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业开票信息禁用与启用
export const cmpInvoiceEnableOrDisablt = (data, successCallback, failureCallback) => {
  postRequest('/cmp/invoice/enableOrDisablt', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 设置默认地址
export const cmpInvoiceUpdateDefaultAddress = (data, successCallback, failureCallback) => {
  postRequest('/cmp/invoice/updateDefaultAddress', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
